// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("bootstrap/dist/js/bootstrap")
require("bootstrap")
require('jquery')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

function showLightboxContact() {
  $('.lightbox-contact').show();
  $('.lightbox-contact .box .notification').hide();
  $('.lightbox-contact .box form').show();
}

function hideLightboxContact() {
  $('.lightbox-contact').hide();
  $('.lightbox-contact .box .notification').hide();
  $('.lightbox-contact .box form').show();
}


$(document).on("turbolinks:load", function(){

  if ($('.lightbox-contact').length) {
    $('.lightbox-contact .box form').bind('ajax:success', function(event) {
      $('.lightbox-contact .box form input').val('');
      $('.lightbox-contact .box form').hide();
      $('.lightbox-contact .box .notification').show();
    });

    $('.btn-contact').click(function() {
      showLightboxContact();
    });

    $(document).mouseup(function(e)
    {
      var container = $(".lightbox-contact .box");
      if (!container.is(e.target) && container.has(e.target).length === 0)
      {
        hideLightboxContact();
      }
    });
  }
});
